<template>
    <v-navigation-drawer
        top
        color="transparent"
        fixed
        height="auto"
        width="70%"
        overlay-color="secondary"
        overlay-opacity="0.8"
        temporary
        v-bind="$attrs"
        v-on="$listeners"
        class="color-icon-menu"
    >
        <v-list
            shaped
            class="nav-responsive"
        >
            <v-list-item
                v-for="name in items"
                :key="name"
                :to="{ name }"
                :exact="name === 'Home'"
                color="white"
                class="white-text "
            >
                <v-list-item-content>
                    <v-list-item-title v-text="name" />
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </v-navigation-drawer>
</template>

<script>
  export default {
    name: 'HomeDrawer',

    props: {
      items: {
        type: Array,
        default: () => ([]),
      },
    },
  }
</script>

<style lang="scss">
.color-icon-menu {
    .nav-responsive {
        border-bottom-right-radius: 12px !important;
    }
}
</style>